
export const spanishLang = {
    authPage: {
        title: "Verifique su correo electrónico",
        linkInstructionsParagraph: "Se enviará un enlace a su correo electrónico para iniciar sesión",
        emailPlaceHolder: "Dirección de correo electrónico",
        spanMsg1: "Enlace de correo electrónico enviado...",
        spanMsg2: "Verificar mi correo electrónico",
        alertMsgParagraph: "Gracias por confirmar su dirección de correo electrónico, recibirás un correo electrónico de noreply@simplifiedsystemprocess.com para registrarse.",
        alertMsgNoRefresh: "Por favor no refresque la página o haga 'click' al botón más de una vez."
    },

    authPageSms: {
        title: "Verifique su número de teléfono",
        instructions: "Recibirá un mensaje SMS con un código de acceso único de 6 dígitos para iniciar sesión",
        phoneLabel: "Número de teléfono",
        otpTitle: "Ingrese un código de verificación",
        phonePlaceHolder: "Número de teléfono",
        spanMsgPhone1: "Código enviado...",
        spanMsgPhone2: "Obtenga el código",
        otpPlaceHolder: "Ingrese el codigo",
        signInButton: "Iniciar sesión",
        alertMsgParagraphPhone: "Gracias por confirmar tu número de teléfono. Recibirá un mensaje de texto con un código de acceso único para iniciar sesión en simplifiedsystemprocess.",
        alertMsgNoRefreshPhone: "Por favor no refresque la página o haga 'click' al botón más de una vez."
    },
    
    patientInfoPage: {
        title: "Información del paciente",
        formLabelFname: "Nombre",
        formLabelLastName: "Apellido",
        formLabelAddress: "Dirección",
        formLabelCity: "Ciudad",
        formLabelState: "Estado",
        formLabelZip: "Código Postal",
        formLabelPhone: "Teléfono",
        formLabelEmail: "Correo electrónico",
        formLabelPaymentType: "Tipo de pago",
        dropDownOptionDeposit: "Depósito",
        dropDownOptionPayment: "Pago parcial",
        radioLabelTitle: "¿Quién paga?",
        radioLabelOptionPatient: "Paciente",
        radioLabelOptionPayor: "Otra persona",
        formLabelAmount: "Monto del pago",
        formFeedBackMsg: "la cantidad ingresada debe estar entre $250 y $1000",
        formFeedBackMsgPartialPayment: "la cantidad ingresada debe estar entre $500 y $20,000",
        button: "ACEPTAR"
    },
    
    patientId: {
        title: "Documentos de Identificación del Paciente",
        instructionsTitle: "Cómo tomar una foto correcta de tu DNI",
        button: "Siguiente",
        instruction1: "No cargue el mismo documento dos veces. Debe cargar un documento separado para cada uno.",
        instruction2: "Utilizar solo documentos legibles, válidos y actualizados. Asegúrese de que el documento no esté dañado o sea ilegible.",
        instruction3: "Utilice solo documentos no vencidos.",
        instruction4: "Evitar errores de imagen. Al tomar una foto digital de su documento, asegúrese de que las cuatro esquinas estén visibles y de que no haya deslumbramiento ni otra obstrucción.",
        instruction5: "Envíe tanto el anverso (Front) como el reverso (Back) de los documentos cuando se le solicite. Algunos documentos (como las licencias de conducir) tienen información importante en el reverso.  Envíe una imagen del reverso del documento cada vez que se le solicite.",
    },

    payorId: {
        title: "Documentos de Identificación del Pagador",
        instructionsTitle: "Cómo tomar una foto correcta de tu DNI",
        button: "Siguiente", 
    },
    
    patientInvoice: {
        title: "Detalles del Contrato",
        button: "Siguiente"
    },
    
    patientContract: {
        title: "Contrato del paciente",
        button: "Siguiente"
    },
    
    paymentAuthorization: {
        title: "Autorización de pago",
        payDate: "Fecha de pago",
        formLabelName: "Nombre que aparece en la tarjeta de crédito*",
        formLabelPayorEmail: "Correo electrónico de la persona que paga*",
        formLabelCardNumber: "Número de tarjeta de crédito*",
        formLabelExpDate: "Fecha de vencimiento*",
        formFeedBackDate: "Ingrese la fecha",
        formLabelCvc: "CVC/CVV*",
        formFeedBackCvc: "Ingrese el código de seguridad",
        formLabelZip: "Código postal*",
        formLabelAddress: "Dirección de facturación*",
        formLabelCity: "Ciudad*",
        formLabelState: "Estado*",
        paragraph1: "Yo",
        paragraph2: "autorizo a SPRING PLASTIC SURGERY a retirar dinero de mi cuenta por la cantidad mencionada en este formulario.  Este pago se destinará al saldo de",
        paragraph3: "y soy consciente de que este depósito es",
        paragraph4: "NO REEMBOLSABLE",
        paragraph5: ". Soy consciente de que DEBO ESTAR PRESENTE y traer la tarjeta que se está utilizando antes del día del preoperatorio.  De lo contrario, el cirujano tiene derecho a cancelar la cirugía.",
        formLabelAgreement: "Acepto los términos",
        button: "PAGAR",
        encryptionMsg: "La información de su tarjeta de crédito está encriptada",
        declineHeader: "No hemos podido procesar su pago",
        unableToAuthenticateMsg: "La transacción no pudo autenticarse. Utilice otra forma de pago.",
        expirationValidationMain: "El formato de fecha de vencimiento es MMYY",
        validationNoSpecialCharacters: "Sólo se permiten números",
        expirationValidationMonth: "Ingrese el mes de vencimiento válido",
        expirationValidationYear: "Ingrese el año de vencimiento válido",
        expirationValidationDate: "Ingrese una fecha de vencimiento válida"
    },
    
    confirmation: {
        Header: "Su pago se ha realizado correctamente",
        transId: "ID de transacción:",
        confirmationMsg: "Gracias por reservar con Spring Plastic Surgery",
        emailMsg: "Revisa el correo electrónico para ver el recibo"
    },
    
    
    Error401: {
        title: "401 No autorizado"
    },
    
    Error401LinkExpired: {
        title: "El enlace ha caducado. Póngase en contacto con su coordinador de cirugia para obtener un nuevo enlace."
    },
    
    Error401LinkCancelled: {
        title: "El enlace ha sido cancelado. Póngase en contacto con su coordinador de cirugia para obtener un nuevo enlace."
    },
    
    Error401StepsComplete: {
        title: "El proceso se ha completado y se ha realizado el pago.  No se requiere ninguna otra acción.  Póngase en contacto con su coordinador de cirugia si tiene más preguntas."
    },
    
    Error403: {
        title: "403 Enlace Prohibido"
    },
    
    Error404: { 
        title: "404 Enlace No encontrado"
    },
    
    errorPayment: {
        title: "Error al procesar el pago. Póngase en contacto con su coordinador de cirugia para obtener más información"
    },
    modalComponent: {
        title: "Tarjeta rechazada",
        body1: "No se puede procesar el pago.",
        body2: "Verifique que la información que ingresó sea correcta."
    }
}